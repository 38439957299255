.content_table_add_button {
    margin: 0 auto !important;
    display: block !important;
}

.content_table_tbody {
    & td {
        height: 75px;
        z-index: 999;
        width: 100%;
        text-align: center;
    }

    &>tr:nth-of-type(2n) {
        background-color: #ebebeb;
    }
}

.content_table_cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & input {
        width: 100%;
    }
}

.content_table_buttons_cell {
    width: 150px;
}

.content_table_info_cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.date_picker {
    border: none;
    padding: 6px;
    border-bottom: 1.5px solid darkgray;
}

.content_table_head {
    & th {
        width: 100%;
        text-align: center;
    }
}