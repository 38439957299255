.editor_field_wrapper {
    width: 100%;
    background-color: blue;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
}

.editor_field_text_field {
    background-color: #fff;
    border-radius: .3rem;
    flex: 1;
}

.editor_sum_field {
    margin-left: 1rem;
    min-width: 250px;
    background-color: #fff;
    border-radius: .3rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}