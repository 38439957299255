.nav_wrapper {
    display: flex;
    padding: 1rem;
    flex-direction: row;
    border-radius: .5rem;
    background-color: #3486c7;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.spacer {
    flex: 1;
}

.nav_logo {
    padding: 1rem;
    border-radius: .25rem;
    background-color: #fff;
}

.nav_company {
    color: #fff;
    padding: 1rem;
    font-size: 1.1rem;

    & p:first-of-type {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.nav_dialog_content {
    display: flex;
    gap: 1rem;
    padding-top: 1rem !important;
    flex-direction: column;
}

.nav_data_button {
    display: flex;
    align-items: center;
    justify-content: center;
}